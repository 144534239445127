import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AnimatedButton } from "../shared/button";
import { FaMinus, FaPlus } from "react-icons/fa6";
import Bitcoin1 from "../../images/bitcoin-1.png";
import Bitcoin2 from "../../images/bitcoin-2.png";
import { Transition } from "../../animation/transition";

const FAQs = [
  {
    question: "Why should I join Discord?",
    answer:
      "Our Discord is the heart of Whalesnipr – a thriving hub where traders, investors, and developers from around the world unite with a shared goal: to build generational wealth for ourselves and our families. By joining, you’ll become part of a supportive, tight-knit community where ideas flow, strategies are shared, and success stories are made. Come join us for free and discover firsthand why we’re called the Whalesniprs!",
  },
  {
    question: "Do I have to be a Noble member to use the crypto tools?",
    answer:
      "Not at all! We offer a variety of powerful, free tools available to everyone in our Discord server. Whether you’re just starting out or already deep into the market, you’ll have access to resources designed to help you trade smarter. Noble Membership, however, unlocks even more advanced tools and insider insights for those wanting to take it to the next level!",
  },
  {
    question: "Do you have group calls?",
    answer:
      "Absolutely! We host two voice calls every day where our community comes together to share insights, break down trading strategies, and explore ways to maximize market opportunities. It’s the perfect space to learn, ask questions, and stay ahead of the game with fellow Whalesniprs!",
  },
  {
    question: "What is Noble membership and why should I join?",
    answer:
      "Noble Membership is our exclusive private group within the Whalesnipr Discord, designed for serious crypto investors who want to take their trading to the next level. As a Noble member, you’ll gain access to a powerful network of elite traders, developers, and insiders who share valuable market insights before they go public. You’ll also unlock advanced tools and resources — some of which are only available to Noble members. If you’re ready to stay ahead of the curve and maximize your potential, Noble Membership is where you belong!",
  },
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="flex  justify-center flex-col items-center xl:px-[9.5rem] relative  p-0 md:mt-[24rem] mt-12 w-full">
      <div className="absolute md:block hidden top-[6rem] left-2 animate-flip  delay-100">
        <img src={Bitcoin2} className="h-14 w-14" alt="crypto coin" />
      </div>
      <div className="absolute  bottom-[6rem] md:block hidden right-2  animate-flip  delay-200">
        <img src={Bitcoin1} className="h-20 w-20" alt="crypto coin" />
      </div>
      <h1 className="text-center hidden xl:block text-white md:text-[2.3rem]  font-normal mb-2">
        Frequently Asked Questions
      </h1>
      <h1 className="text-center block xl:hidden text-white md:text-[2.3rem]  font-normal mb-2">
        FAQ's
      </h1>
      <p className="text-[#8F8F8F]  font-raleway mb-20 tracking-[0.011rem] text-center md:max-w-md w-[80%]">
        Find questions and answers related to Whalesnipr, updates and support.
      </p>

      <div className="w-full font-raleway max-w-[50rem]">
        {FAQs.map((faq, index) => (
          <Transition key={index}>
            <div className="mb-4 rounded-2xl   overflow-hidden">
              <button
                className={`w-full text-left px-6 py-4 flex justify-between items-center text-white transition-all duration-500 ease-in-out  ${
                  activeIndex === index
                    ? "bg-[#3F1346] rounded-t-2xl border-none"
                    : "bg-gradient-to-b from-white/0 to-gray-500/[0.06] border border-[#FFFFFF1A] rounded-2xl hover:bg-gray-500/10"
                }`}
                onClick={() => toggleFAQ(index)}
              >
                <span> {faq.question}</span>
                <motion.span
                  className="text-[#8f8f8f]"
                  animate={{ rotate: activeIndex === index ? 180 : 0 }}
                  transition={{ duration: 0.5, ease: [0.4, 0, 0.2, 1] }}
                >
                  {activeIndex === index ? <FaMinus /> : <FaPlus />}
                </motion.span>
              </button>

              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{
                      height: "auto",
                      transition: {
                        duration: 0.5,
                        ease: [0.4, 0, 0.2, 1],
                      },
                    }}
                    exit={{
                      height: 0,
                      transition: {
                        duration: 0.5,
                        ease: [0.4, 0, 0.2, 1],
                      },
                    }}
                    className="bg-[#3F1346] overflow-hidden "
                  >
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          duration: 0.3,
                          delay: 0.1,
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                      className="p-6 text-white "
                    >
                      {faq.answer}
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </Transition>
        ))}
      </div>
      <div className="mt-8">
        <a
          href="https://discord.gg/8W8TdfKXMT"
          target="_blank"
          rel="noreferrer"
        >
          <AnimatedButton buttonText={"More FAQ"} />
        </a>
      </div>
    </section>
  );
};

export default FAQSection;
