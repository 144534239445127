import React from "react";
import Logo from "../../images/logo.svg";
import {
  FaXTwitter,
  FaDiscord,
  FaTelegram,
  FaYoutube,
  FaGithub,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="w-full relative mt-24">
      <div className="w-full font-raleway z-50 absolute bottom-0 py-2 flex lg:flex-row flex-col space-y-8 md:space-y-0  justify-between items-center">
        <a href="https://whalesnipr.com">
          <div className="flex items-center md:gap-3 gap-1">
            <span className="text-white md:text-2xl text-[1.25rem]  font-bold">
              Whale<span className="text-[#75FB69]">Sniper</span>
            </span>
            <img
              className="md:h-[41px] md:w-[41px] w-[24px] h-[24px]"
              src={Logo}
              alt="Whalesnipr Logo"
            />
          </div>
        </a>

        {/* Social Links */}
        <div className="flex items-center space-x-6 ">
          <a
            href="https://x.com/whalesnipr"
            target="_blank"
            rel="noreferrer"
            className="text-primary-color hover:text-purple-400 transition-colors"
          >
            <FaXTwitter size={24} />
          </a>
          <a
            href="https://t.me/+MerO0iO9w7pmOGI0"
            target="_blank"
            rel="noreferrer"
            className="text-primary-color hover:text-purple-400 transition-colors"
          >
            <FaTelegram size={24} />
          </a>
          <a
            href="https://discord.gg/8W8TdfKXMT"
            target="_blank"
            rel="noreferrer"
            className="text-primary-color hover:text-purple-400 transition-colors"
          >
            <FaDiscord size={24} />
          </a>
          <a
            href="https://www.youtube.com/@Whalesnipr"
            target="_blank"
            rel="noreferrer"
            className="text-primary-color hover:text-purple-400 transition-colors"
          >
            <FaYoutube size={24} />
          </a>
          <a
            href="https://github.com/Whalesnipr-Foundation"
            target="_blank"
            rel="noreferrer"
            className="text-primary-color hover:text-purple-400 transition-colors"
          >
            <FaGithub size={24} />
          </a>
        </div>

        {/* Copyright */}
        <div className="text-[#FCDDEC] text-sm">
          @WhaleSniper 2024. All rights reserved
        </div>
      </div>
      <div className="md:h-[40rem] h-[35rem] w-full absolute text-white z-20 bottom-0 bg-gradient-to-b from-[#11011E]/0 from-[27.5%] via-[#11011E] via-[39.5%] to-[#11011E]" />
    </footer>
  );
};

export default Footer;
