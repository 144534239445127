import React, { useContext, useMemo } from "react";
import { motion } from "framer-motion";
import { IntersectionContext } from "./intersectionObserver";

export const AnimationVariant = ({
  children,
  duration = 0.8,
  delay = 0,
  easing = [0.22, 1, 0.36, 1],
}) => {
  const { inView } = useContext(IntersectionContext);

  const transition = useMemo(
    () => ({
      duration,
      delay,
      ease: easing,
    }),
    [duration, delay, easing]
  );

  const variants = {
    hidden: {
      opacity: 0,
      y: 30,
      scale: 0.95,
      filter: "blur(8px)",
      transition,
    },
    show: {
      opacity: 1,
      y: 0,
      scale: 1,
      filter: "blur(0px)",
      transition: {
        ...transition,
        duration: duration * 1.2,
        opacity: { duration: duration * 0.8 },
        filter: { duration: duration * 0.8 },
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate={inView ? "show" : "hidden"}
      variants={variants}
      style={{ willChange: "transform" }}
    >
      {children}
    </motion.div>
  );
};
