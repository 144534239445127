import { Helmet } from "react-helmet-async";
import FAQSection from "../components/ui/FAQ";
import Contact from "../components/ui/contact";
import Hero from "../components/ui/hero";
import Testimonial from "../components/ui/testimonial";
import Tools from "../components/ui/tools";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Whalesnipr - Track Crypto Whale Movements | Home</title>
        <meta
          name="description"
          content="Monitor cryptocurrency activities, get real-time alerts, and analyze market trends with Whalesnipr's advanced tracking tools."
        />

        {/* Keywords */}
        <meta
          name="keywords"
          content="crypto tracker, cryptocurrency monitoring, whale alerts, crypto market analysis, blockchain whales, ai agents, solana, wins"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Whalesnipr - Level up
your Crypto Game | Home"
        />
        <meta
          property="og:description"
          content="Monitor your cryptocurrency activities, get real-time alerts, and analyze market trends with Whalesnipr's advanced tracking tools."
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo.svg" />

        {/* Twitter */}
        <meta
          name="twitter:title"
          content="Whalesnipr - Level up
your Crypto Game | Home"
        />
        <meta
          name="twitter:description"
          content="Monitor cryptocurrency activities, get real-time alerts, and analyze market trends with Whalesnipr's advanced tracking tools."
        />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.whalesnipr.com/" />
      </Helmet>

      <Hero />
      <Tools />
      <Testimonial />
      <FAQSection />
      <Contact />
    </>
  );
};

export default Home;
