import React from "react";
import { IntersectionObserver } from "./intersectionObserver";
import { StaggerWrap } from "./stagger-wrap";
import { AnimationVariant } from "./animation-variant";

export const Transition = ({ children, staggerDuration = 0.1 }) => (
  <IntersectionObserver>
    <StaggerWrap staggerDuration={staggerDuration}>
      <AnimationVariant>{children}</AnimationVariant>
    </StaggerWrap>
  </IntersectionObserver>
);
