import { Analytics } from '@vercel/analytics/react';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import './App.css';
import Footer from './components/ui/footer';
import Navbar from './components/ui/navbar';

function App() {
  return (
    <HelmetProvider>
      <div className='relative'>
        {/* Left gradient blur */}
        <div className='absolute md:w-1/4 w-[5rem] md:h-[400px] h-[200px] left-0 top-48 blur-[100px] bg-gradient-to-br from-teal-800 via-teal-600 to-transparent opacity-60' />

        {/* Right gradient blur */}
        <div className='absolute md:w-1/4 w-[5rem] md:h-[400px] h-[200px] right-0 top-48 blur-[120px] bg-gradient-to-b from-indigo-900 via-purple-700 to-blue-600 opacity-50' />

        <div className='min-h-screen container mx-auto scroll-smooth bg-gradient-to-br  relative overflow-hidden'>
          <ScrollRestoration />
          <div className='p-6'>
            <Navbar />
            <main className='grid place-content-center'>
              <Outlet />
            </main>
            <Footer />
          </div>
          <Analytics />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
