import React from "react";

export const AnimatedButton = ({ buttonText }) => {
  return (
    <div className="relative group inline-block">
      <div className="absolute  -inset-[1px] bg-gradient-to-r from-[#8F00FF] to-[#FF00D6]  opacity-75 group-hover:opacity-100 transition-opacity duration-500"></div>

      <button
        className="relative px-4 py-2 bg-[#11011e] font-press-start text-[#FF00D6] uppercase 
      hover:bg-gradient-to-r hover:from-[#8F00FF] from-20% hover:to-[#FF00D6] to-70% 
      hover:text-black transition-all duration-500 "
      >
        {buttonText}
      </button>
    </div>
  );
};
