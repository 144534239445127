import React from "react";
import { motion } from "framer-motion";

export const StaggerWrap = ({ children, staggerDuration = 0.1 }) => {
  return (
    <motion.div
      initial="hidden"
      animate="show"
      variants={{
        show: {
          transition: {
            staggerChildren: staggerDuration,
          },
        },
      }}
    >
      {children}
    </motion.div>
  );
};
