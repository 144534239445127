import React from "react";
import { AnimatedButton } from "../shared/button";

import Heremes from "../../images/hermes_new.png";
import PumpCalculator from "../../images/pump_calculator_new.png";
import OGPump from "../../images/og_pump_new.png";
import { Link } from "react-router-dom";
import Bitcoin1 from "../../images/bitcoin-1.png";
import Bitcoin2 from "../../images/bitcoin-2.png";
import Looper from "../../images/LooperGroup.png";
import { Transition } from "../../animation/transition";

const Tools = () => {
  const cards = [
    {
      id: 1,
      title: "Heremes",
      subtitle: "Mass Sniper",
      description:
        "Buy tokens and split across many wallets. Multiply holders in an instant.",
      demoLink: "https://youtu.be/PKWbbbhznq8?si=WUlOhPSjGnEAogEy",
      accessLink: "https://discord.gg/8W8TdfKXMT",
      image: Heremes,
      showDemo: true,
    },
    {
      id: 2,
      title: "Pump Calculator",
      subtitle: "Trade Calculator",
      description:
        "Stop blowing your port. Find out how much you actually pay in fees. Trade smart.",
      accessLink: "https://pumpcalculator.fun",
      image: PumpCalculator,
      showDemo: false,
    },
    {
      id: 3,
      title: "OG Pump",
      subtitle: "Find OG Coins",
      description:
        "This tool helps you find OG coins. Analyse tokens quickly. Verify Authenticity",
      accessLink: "https://ogpump.fun",
      image: OGPump,
      showDemo: false,
    },
  ];

  return (
    <section className="xl:px-[9.5rem] relative md:px-[3rem] p-0">
      <img
        src={Looper}
        alt="tools background"
        className="  bottom-[28rem] -z-30 object-cover absolute"
      />
      <h2 className="text-center text-white  md:text-[2.3rem] font-normal mb-10">
        Tools
      </h2>
      <div className="absolute md:top-[40rem] md:left-20 top-[8rem] -left-10 animate-flip  delay-100">
        <img
          src={Bitcoin1}
          className="h-10 w-10 md:w-full md:h-full"
          alt="crypto coin"
        />
      </div>
      <div className="absolute md:-top-10 top-[9rem] md:right-10 -right-10 animate-flip delay-300">
        <img
          className="h-12 w-12 md:w-24 md:h-24"
          src={Bitcoin2}
          alt="crypto coin"
        />
      </div>
      <div className="grid grid-cols-1  font-raleway md:grid-cols-2 xl:grid-cols-3 gap-10">
        {cards.map((card) => (
          <Transition key={card.id}>
            <div className="relative group flex bg-[#130122]">
              <div className="absolute   -inset-[3px] bg-gradient-to-r from-[#8F00FF] to-[#FF00D6]  opacity-75 group-hover:opacity-100 transition-opacity duration-500"></div>
              <div className="relative bg-inherit flex-grow">
                <img
                  src={card.image}
                  alt="Architecture"
                  className="w-full h-[252px] object-cover"
                />
                <div className="p-4 pb-8 space-y-4">
                  <div className="space-y-2 lg:h-[150px] h-[170px]">
                    <h2 className="text-xl uppercase font-press-start font-bold text-white">
                      {card.title}
                    </h2>
                    <h3 className="text-lg font-semibold text-primary-color">
                      {card.subtitle}
                    </h3>
                    <p className="text-gray-300 text-sm">{card.description}</p>
                  </div>

                  <div className="flex gap-4  relative bottom-0 justify-between">
                    {card.showDem ? (
                      <a href={card.demoLink} target="_blank" rel="noreferrer">
                        <AnimatedButton buttonText={"Watch Demo"} />
                      </a>
                    ) : (
                      <AnimatedButton buttonText={"Watch Demo"} />
                    )}

                    {card.comingSoon ? (
                      <AnimatedButton buttonText={"Coming Soon"} />
                    ) : (
                      <a
                        href={card.accessLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AnimatedButton buttonText={"Get Access"} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        ))}
      </div>

      <Link className="mt-10 grid place-content-center w-full" to="/tools">
        <AnimatedButton buttonText={"More Tools"} />
      </Link>
    </section>
  );
};

export default Tools;
