import React from "react";

export const TestimonialStar = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="56"
      viewBox="0 0 64 56"
      fill="none"
    >
      <path
        d="M31.6609 1.26211C31.7468 0.695328 32.5637 0.69533 32.6496 1.26211L36.1705 24.495C36.2045 24.7196 36.3856 24.893 36.6114 24.9173L63.5259 27.8075C64.1214 27.8715 64.1214 28.7379 63.5259 28.8018L36.6114 31.6921C36.3856 31.7164 36.2045 31.8898 36.1705 32.1143L32.6496 55.3473C32.5637 55.914 31.7468 55.914 31.6609 55.3473L28.1401 32.1143C28.1061 31.8898 27.9249 31.7164 27.6991 31.6921L0.784669 28.8018C0.189185 28.7379 0.189186 27.8715 0.78467 27.8075L27.6991 24.9173C27.9249 24.893 28.1061 24.7196 28.1401 24.495L31.6609 1.26211Z"
        fill="#4F4F4F"
      />
    </svg>
  );
};
export const TestimonialBlob = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="674"
      height="486"
      viewBox="0 0 674 486"
      fill="none"
    >
      <path
        opacity="0.7"
        d="M392.605 0.834079C318.071 -3.85171 103.396 4.85963 10.0269 198.739C-30.9956 316.259 58.7819 421.78 224.605 444.834C428.023 473.115 567.105 558.834 650.605 344.334C720.786 164.048 631.229 15.8359 392.605 0.834079Z"
        fill="url(#paint0_linear_192_610)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_192_610"
          x1="337"
          y1="0"
          x2="337"
          y2="485.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FB01D7" />
          <stop offset="1" stop-color="#3B0C4F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const HeroBlob1 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1021"
      height="1274"
      viewBox="0 0 1021 1274"
      fill="none"
    >
      <g filter="url(#filter0_f_134_3304)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M883.183 874H446.728C380.893 788.449 371.568 698.282 514.401 664.376C733.633 612.335 748.724 528.826 759.427 469.602C766.741 429.131 772.005 400 838.963 400C914.458 400 976.256 434.3 1021.08 486.518V819.769C1006.28 825.742 990.364 831.071 974.129 836.508C943.178 846.873 911.052 857.632 883.183 874Z"
          fill="#60FFE7"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_134_3304"
          x="0.75"
          y="0"
          width="1420.33"
          height="1274"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="200"
            result="effect1_foregroundBlur_134_3304"
          />
        </filter>
      </defs>
    </svg>
  );
};
export const HeroBlob2 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="775"
      height="1346"
      viewBox="0 0 775 1346"
      fill="none"
    >
      <g filter="url(#filter0_f_134_3301)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M147.485 945.499C262.717 874.874 375 665.321 375 542.021C375 479.124 355.214 432.702 322.244 400H0V945.233C0.122558 945.322 0.245219 945.411 0.367984 945.499H147.485Z"
          fill="#7000FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_134_3301"
          x="-400"
          y="0"
          width="1175"
          height="1345.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="200"
            result="effect1_foregroundBlur_134_3301"
          />
        </filter>
      </defs>
    </svg>
  );
};
