import React from "react";
import { AnimatedButton } from "../shared/button";
import Bitcoin1 from "../../images/bitcoin-1.png";
import Bitcoin2 from "../../images/bitcoin-2.png";
import Bitcoin3 from "../../images/bitcoin-3.png";
import Bitcoin4 from "../../images/bitcoin-4.png";
import Discord from "../../images/discord.png";
import Pattern from "../../images/hero-pattern.png";
import Star from "../../images/star-img.svg";
import HeroImage from "../../images/circles.png";
import { Transition } from "../../animation/transition";

const Hero = () => {
  return (
    <section className={`w-full min-h-screen md:mt-20 mt-0 relative`}>
      <img
        src={HeroImage}
        alt="hero background"
        className="w-full -z-30 h-screen object-cover absolute"
      />

      <div className=" grid place-content-center ">
        <div className="absolute md:top-40 md:left-20 top-[6rem] left-4 animate-flip  delay-100">
          <img
            src={Bitcoin1}
            className="h-10 w-10 md:w-full md:h-full"
            alt="crypto coin"
          />
        </div>
        <div className="absolute md:top-80 md:left-10 top-[25rem] left-0  animate-flip  delay-200">
          <img
            className="h-14 w-14 md:w-full md:h-full"
            src={Bitcoin2}
            alt="crypto coin"
          />
        </div>
        <div className="absolute md:top-72 top-[6rem] md:right-40 right-2 animate-flip delay-300">
          <img
            className="h-12 w-12 md:w-full md:h-full"
            src={Bitcoin3}
            alt="crypto coin"
          />
        </div>
        <div className="absolute md:top-32 md:right-28  right-16 top-20 animate-flip delay-400">
          <img src={Bitcoin4} alt="crypto coin" />
        </div>

        {/* Stars */}

        <div className="absolute md:top-60 top-24 md:right-28 right-0">
          <img
            className="md:w-[30px] md:h-[30px] h-[20px] w-[20px]"
            src={Star}
            alt="star"
          />
        </div>
        <div className="absolute md:top-60 top-[8rem] md:left-40 left-[55px]">
          <img
            className="md:w-[45px] md:h-[45px] h-[20px] w-[20px]"
            src={Star}
            alt="star"
          />
        </div>
        <div className="absolute md:top-32 top-10 md:right-60 right-20">
          <img
            className="md:w-[45px] md:h-[45px] h-[30px] w-[30px]"
            src={Star}
            alt="star"
          />
        </div>

        {/* Hero Content */}
        <Transition>
          <div className="text-center lg:pb-10 pb-6 md:pt-32 pt-[11rem]">
            <h1 className="lg:text-6xl text-2xl font-[400] flex flex-col text-transparent  lg:mb-6 mb-4">
              <span className="bg-gradient-to-b from-[#D0F] to-[#850099] bg-clip-text text-transparent">
                Level up
              </span>

              <span className="lg:mt-3 lg:w-[57rem] lg:leading-[4.6rem] leading-[2rem] mx-auto w-full text-center text-white">
                your Crypto Game
              </span>
            </h1>
            <p className="text-white font-raleway lg:text-lg sm:font-[400] w-[17.6rem] mx-auto lg:w-full mb-4">
              Only at WhaleSniper, you can build a good portfolio and learn
              <br />
              best practices about cryptocurrency
            </p>
            <a
              href="https://discord.gg/8W8TdfKXMT"
              target="_blank"
              rel="noreferrer"
            >
              <AnimatedButton buttonText={"Join Now"} />
            </a>
          </div>
        </Transition>
        <div className="w-full xl:px-0 px-0 md:px-[3rem]">
          <img width={1024} src={Pattern} alt="discord" />
        </div>
      </div>

      <Transition>
        <div className="w-full xl:px-[9.5rem]  px-0 md:px-[3rem] grid place-content-center relative lg:-top-40 -top-10 ">
          <div className="h-6  w-full rounded-t-xl bg-white items-center gap-2 px-2 flex">
            <span className="h-3 w-3 rounded-full bg-orange-600" />
            <span className="h-3 w-3 rounded-full bg-gray-200" />
            <span className="h-3 w-3 rounded-full bg-green-500" />
          </div>
          <img
            className="min-w-full rounded-b-xl object-cover"
            src={Discord}
            alt="discord"
          />
        </div>
      </Transition>
    </section>
  );
};

export default Hero;
