import React, { createContext } from "react";
import { useInView } from "react-intersection-observer";

export const IntersectionContext = createContext({ inView: false });

export const IntersectionObserver = ({ children, threshold = 0.1 }) => {
  const [ref, inView] = useInView({
    threshold,
    // triggerOnce: true,
  });

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div ref={ref}>{children}</div>
    </IntersectionContext.Provider>
  );
};
